export default {
  EDIT_APPLICATION: 'EditApplicationDialog',
  SAVE_CLIENT: 'SaveClientDialog',
  SAVE_USER_CLIENT: 'SaveUserClientDialog',
  SAVE_INVITE: 'SaveInviteDialog',
  SAVE_USER: 'SaveUserDialog',
  SAVE_PROFILE: 'SaveProfileDialog',
  SAVE_PLATFORM: 'SavePlatformDialog',
  SAVE_WEB_PLATFORM: 'SaveWebPlatformDialog',
  SAVE_TARIFF: 'SaveTariffDialog',
  SAVE_PRICE: 'SavePriceDialog',
  DELETE_PRICE: 'DeletePriceDialog',
  SAVE_INVOICE: 'SaveInvoiceDialog',
  CONFIRM_INVITE: 'ConfirmInviteDialog',
  CONFIRM_DELETE: 'ConfirmInviteDialog',
  CONFIRM_ADD_PRICING_PLAN: 'ConfirmAddPricingPlanDialog',
  ADD_2FA: 'Add2FA',
  OPEN_PHOTO_DOC: 'OpenPhotoDoc',
  OPEN_DOCK_CHECK_SOURCES: 'OpenDocCheckSources',
  CHANGE_PASSWORD: 'ChangePassword',
  WORKFLOW_BUILDER: 'WorkflowBuilder',
  ADD_FLOW_STEP: 'AddFlowStep',
  QUESTIONNAIRES: 'Questionnaire',
  ROLE_MANAGEMENT: 'RoleManagement',
  ONGOING_MONITORING: 'OngoingMonitoring',
  AML_CARD_DETAILS: 'AmlCardDetails',
  RESOLVE_WARNING: 'ResolveWarning',
  APPROVE_OR_DECLINE_COMMENT: 'ApproveOrDeclineComment',
  CHANGE_SERVICE_STATUS: 'ChangeServiceStatus',
  CONFIRM_DIALOG: 'ConfirmDialog',
  LOG_MAP: 'LogMap',
  DEVICE_GEO: 'DeviceGeo',
};
